.__sec_head {
    margin-bottom: 30px;
    font-weight: 500;
    line-height: 1.2;
    position: relative;
    font-size: 2.5rem;
}

.__videos-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
}

.__photos-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    grid-gap: 30px;

    img {
        height: 170px;
        object-fit: cover;
        width: 100%;
        box-shadow: 0px 0px 10px #0005;
        border: 2px solid #fff;
        border-radius: 10px;
        cursor: pointer;
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    border-radius: 10px;
    overflow: hidden;
    background: #000;
    box-shadow: 0px 0px 10px #0005;
    border: 2px solid #fff;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
.__reset_modal {


    .__reset_content {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        .____reset_head {
            font-size: 26px;
            font-weight: 400;
            margin-bottom: 30px;
        }

    }
}
.__login_modal {
    min-width: 60vw;

    @media (max-width: 1356px) {
        min-width: 90vw;
    }

    .ant-modal-content {
        background-color: #e9e9e9;
        color: #333;
        border-radius: 20px;
        padding: 0;
        overflow: hidden;
    }


    .__log_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: opacity 0.4s;
        transition: 0.4s;
        justify-content: space-between;
        height: 100%;

        .__loginhead {
            font-size: 23px;
            font-weight: 500;
            margin-bottom: 30px;
            margin-top: 20px;
            width: 100%;
            text-align: justify;
        }

        .__facebook {
            border-radius: 50px;
            border: 0;
            font-size: 13px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0em;
            display: flex;
            align-items: center;
            justify-content: center;
            grid-gap: 15px;

            img {
                background: #1877F2;
                border-radius: 100px;
                padding: 1px;
            }
        }

        .__google {
            border-radius: 50px;
            border: 0;
            font-size: 13px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0em;
            display: flex;
            align-items: center;
            justify-content: center;
            grid-gap: 15px;

            img {
                border-radius: 100px;
                padding: 1px;
            }
        }

        .__forget {
            width: 100%;
            text-align: right;
            margin-top: 15px;

            a {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
            }
        }

        .__new_signup {
            font-size: 13px;
            font-weight: 300;
            color: #ACACAC;
            text-align: left;
            width: 100%;
            margin: 0;

            a {
                font-size: 14px;
                color: #000;
                font-weight: 400;
            }
        }

        .__border {
            background: #eee;
            width: 100%;
            height: 1px;
            margin-top: 25px;
            margin-bottom: 25px;
        }

    }
}

._______social_media {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-content: center;

    button {
        border: 1px solid #f7f7f7 !important;
        padding: 6px 30px;
        background: #fbfbfb;
    }

}



.__login_full_wrap {
    display: flex;
    justify-content: space-between;
    min-height: 693px;

    @media (max-width: 556px) {
        flex-direction: column;
    }

    .__login_wrap_left,
    .__login_wrap_right {
        flex: 1;
        padding: 50px;
    }

    .__login_wrap_right {
        background-color: #fff;
    }

    .__left_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .__login__title {
        margin-bottom: 1.5em;
        font-size: 24px;
        font-weight: normal;

        @media (max-width: 556px) {
            margin-bottom: 1em;
        }
    }

    .__login__content {
        img {
            width: 25vw;
            height: 160px;
            object-fit: contain;

            @media (max-width: 556px) {
                width: 55vw;
                height: 60px;
            }
        }
    }

    .__login__footer {
        @media (max-width: 556px) {
            margin-top: 20px;
        }
    }
}


.link-underlined {
    position: relative;
    display: inline-block;
    line-height: 100%;
    color: rgb(34, 34, 34);
    border: none;
    font-weight: bold;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        bottom: -0.2em;
        left: 0;
        width: 100%;
        height: 2px;
        background-repeat: no-repeat;
        background-image: linear-gradient(to right, rgb(34, 34, 34) 45%, rgba(34, 34, 34, 0.3) 55%);
        background-size: 220% 100%;
        background-position: 100% 50%;
        transition: .3s ease-out;

    }

    &:hover:before {
        background-position: 0% 50%;
    }
}


.arabic {

    .__login_modal .__log_content .__loginhead, .__login_full_wrap .__login__title{
        font-size: 30px !important;
    }

    .__login_modal .__log_content .__forget a, .__login__footer{
        font-size: 20px !important;
    }

    .__login_modal .__log_content .__facebook, .__login_modal .__log_content .__google{
        font-size: 18px;
    }
    .__login_modal .__log_content{
        font-size: 20px;
    }

}
.__more_img_subm {
    border: 1px dashed #969EAE;
    border-radius: 24px;
    display: flex;
    align-items: center;
    color: #969EAE;
    justify-content: center;
    margin-right: 20px;
    width: 70px;
    height: 70px;
    cursor: pointer;
}

.__closbt {
    position: absolute;
    top: -5px;
    right: -5px;
    background: #dc3545;
    padding: 3px;
    line-height: 1;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
}

.__more_img_image {
   
    position: relative;

    img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
}
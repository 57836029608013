header {
    z-index: 10;
    width: 100%;
    height: 90px;
    background-color: transparent;
    padding-left: 22px;
    padding-right: 22px;
    display: flex;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    @media screen and (max-width: 568px) {
        height: 70px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.container {
    position: relative;
}

.nav-background {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ececec;
    border-top-style: none;
    border-radius: 0 0 24px 24px;
    transition: transform .4s cubic-bezier(.215, .61, .355, 1);
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: hidden;
    transform: translateY(-100%);

}

.nav-background.is-scrolled {
    transform: translate(0px, 0%);
    box-shadow: 0 24px 48px -24px rgba(56, 56, 138, .16);
}

.__logo {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
    @media screen and (max-width: 568px) {
        grid-gap: 2px;
    }

    img {
        height: 70px;

        @media (max-width: 506px) {
            height: 40px;
        }
    }

    &:hover {
        img {
            transition: transform .4s cubic-bezier(.215, .61, .355, 1);
            opacity: 0.8;
        }
    }
}

.__top_menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 6px;
}

.__center_menu {
    display: flex;
    grid-gap: 30px;

    a {
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        grid-gap: 5px;
        text-decoration: none;
    }

    @media (max-width: 960px) {
        display: none;
    }
}

.__inter_menu {
    padding: 5px 3px;

    h2 {
        font-size: 17px;
        margin: 0;
        font-weight: 400;
        font-family: 'Spoof Regular', sans-serif !important;
        color: #3f3f3f;

    }

    p {
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        margin-top: 5px;
    }
}

.__right_menu {
    display: flex;
    grid-gap: 10px;
    align-items: center;

    a {
        cursor: pointer;
        font-weight: 400;
        background: #e9ecef;
        text-align: center;
        border-radius: 30px;
        height: 30px;
        width: 30px;

    }

    img {
        object-fit: cover;
        object-position: top;
    }

    button {
        background-color: transparent;
        padding: 0px 0px;
        border: 0;
        color: #333;
        min-width: 10px;
        width: 60px;
        height: 30px;
        font-size: 15px;
    }
}

// @media (max-width: 1556px) {
//     .grid-courses--one-row>li:nth-last-child(-n+1) {
//         display: none;
//     }
// }

.__inter_____menu {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;

    img {
        width: 60px;
        height: 60px;
    }

    h2 {
        font-size: 20px;
        margin: 0;
    }

    p {
        font-size: 17px;
        margin: 0;
        color: #777;
    }

    button {
        border: 1px solid;
        background-color: #fff;
    }
}


.___max_dev {
    display: none !important;

    @media (max-width: 556px) {
        display: flex !important;
    }
}

.__min_dev {
    display: flex !important;

    @media (max-width: 556px) {
        display: none !important;
    }
}

.__drawer_menu {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    position: relative;

    .ant-drawer-body {
        padding-bottom: 0px;
    }

    a {
        margin-bottom: 26px;
        font-size: 18px;
        cursor: pointer;
        text-decoration: none;
        width: max-content;
        display: flex;
        align-items: center;
        font-family: 'Axiforma';
        transition: cubic-bezier(0.075, 0.82, 0.165, 1) .3s;

        &:hover {
            color: #afbb53;
        }
    }

    .__so {
        // padding-left: 17px;

        a {
            font-size: 14px;
            margin-bottom: 16px;
        }
    }

    .active {
        text-decoration: none;
        color: #818d26;
        font-weight: 500;
    }

}



.ant-modal {
    font-family: 'Axiforma', sans-serif !important;
}


.__center_menu {
    .active {
        text-decoration: none;
        color: #818d26;
    }

    .subpixel-antialiased[aria-expanded="true"] {
        color: #818d26;
        opacity: 1;
        transform: none;

        svg {
            fill: #818d26;

            path {
                stroke: #818d26;
            }
        }
    }
}

.__menu_outlined {
    width: 30px;
    background-color: transparent;
    display: block !important;
}

.bg-default-200222 {
    background: linear-gradient(0deg, rgba(54, 52, 53, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

.___border__o .bg-content1 {
    box-shadow: none;
    padding: 0 !important;
}

._________________________abc {
    border-bottom: 1px solid #eee;

    &:last-child {
        border-bottom: none !important;
    }
}

.____zero_pd {
    padding: 0 !important;
}

._________________________abc[data-hover="true"] {
    background-color: transparent !important;
    cursor: default !important;
}

.__drop_down_content {
    padding: 30px;
    width: 800px;

    h4 {
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .__dropdown_items_list {
        grid-gap: 25px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

        .__dropdown_single {
            border-radius: 20px;
            padding: 15px;
            display: flex;
            gap: 20px;
            transition: ease-in-out 0.4s;
            background-color: #fff;
            cursor: pointer;

            h5 {
                font-size: 15px;
                font-weight: 600;
                margin-bottom: 6px;
            }

            p {
                font-size: 11px;
                font-weight: 400;
                margin: 0;
                white-space: initial;
            }

            &:hover {
                background-color: #F1F0FC;
                box-shadow: 0px 0px 3px #F1F0FC;
            }
        }

        .__hover_1 {
            &:hover {
                background-color: #F1F0FC;
                box-shadow: 0px 0px 3px #F1F0FC;
            }
        }

        .__hover_2 {
            &:hover {
                background-color: #FFF0F7;
                box-shadow: 0px 0px 3px #FFF0F7;
            }
        }

        .__hover_3 {
            &:hover {
                background-color: #EEFAFF;
                box-shadow: 0px 0px 3px #EEFAFF;
            }
        }

        .__hover_4 {
            &:hover {
                background-color: #EBF7F3;
                box-shadow: 0px 0px 3px #EBF7F3;
            }
        }

        .__hover_5 {
            &:hover {
                background-color: #FEF4EC;
                box-shadow: 0px 0px 3px #FEF4EC;
            }
        }

        .__hover_6 {
            &:hover {
                background-color: #FFFAEA;
                box-shadow: 0px 0px 3px #FFFAEA;
            }
        }

        .__hover_7 {
            &:hover {
                background-color: #EDF1FF;
                box-shadow: 0px 0px 3px #EDF1FF;
            }
        }

        .__hover_8 {
            &:hover {
                background-color: #EEFAFF;
                box-shadow: 0px 0px 3px #EEFAFF;
            }
        }

        .__hover_9 {
            &:hover {
                background-color: #d977d940;
                box-shadow: 0px 0px 3px #d977d940;
            }
        }

    }
}

.__lng_drops {
    display: flex;
    flex-direction: column;

    a {
        cursor: pointer;
        border-radius: 20px;
        padding: 10px;
        transition: 0.4s;

        &:hover {
            background-color: #eee;
        }
    }

    .__activ_lng {
        font-weight: 500;
        color: #97a51a;
    }

}

.arabic {
    .__center_menu {
        a {
            font-size: 20px;
        }
    }

    .__drop_down_content {
        h4 {
            font-size: 25px;

        }
    }

    .__drawer_menu {
        a {
            font-size: 22px;
            font-weight: 600;
        }
    }

    .__drawer_menu {
        .__so {
            a {
                font-size: 19px;
            }
        }
    }

    .__drop_down_content .__dropdown_items_list .__dropdown_single h5 {
        font-size: 21px;
    }

    .__drop_down_content .__dropdown_items_list .__dropdown_single p {
        font-size: 15px;
    }
}
.__search_box {
    padding: 20px;
    .__input_sec {
        display: flex;
        align-items: self-start;
        gap: 10px;
        margin-bottom: 20px;
        div{
            width: 100%;
        }

        input {
            min-width: 300px;
            @media (max-width: 456px) {
                min-width: 200px;
            }
        }

        button {
            height: 48px;
            min-width: 48px;
            padding: 0;

            span {
                margin: 0 !important;
            }
        }

    }

    .__filter_sec {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        @media (max-width: 456px) {
            grid-template-columns: 1fr;
        }

        .__single{
            h5{
                margin-bottom: 10px;
                font-weight: bold;
            }
            .ant-radio-group{
                margin-left: 10px;
            }
        }
    }
}


.__drawer_menu .ant-drawer-body::-webkit-scrollbar {
    width: 2px !important;
}
.__drawer_menu .ant-drawer-body::-webkit-scrollbar-thumb {
    background: #222;
}
.__drawer_menu .ant-drawer-body::-webkit-scrollbar-track {
    background: #22222217;
}
.__ask {
    background: linear-gradient(45deg, #80c134, #CDDC39);
    height: 30px;
    border: none;
    border-radius: 10px;
    color: #ffffff !important;
    cursor: pointer;
    display: flex;
    font: inherit;
    font-size: 17px;
    font-weight: 500;
    justify-content: center;
    outline: inherit;
    padding: 12px 18px;
    transition: 0.5s;
    white-space: nowrap;
    align-items: center;
    gap: 10px;
    width: 44px !important;
    text-shadow: 0px 0px 20px #000;
    img{
        width: 25px;
        height: 25px;
        object-fit: scale-down;
        filter: brightness(100);
    }

}
.select-wrapper {
  label {
    font-size: 0.9rem;
    margin-bottom: 10px;
    display: block;
    font-weight: 600;
  }

  .ant-select-selection-item {
    font-size: 1rem;
    text-transform: capitalize;
  }

  .ant-select-single .ant-select-selector {
    height: 45px;
    padding: 0 20px;
    align-items: center;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #d9d9d9 !important;
  }

  .ant-select {
    height: 45px;
    align-items: center;
    border-radius: 10px;
    background-color: #ffffff;
    border: none;
  }

  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
    padding: 0 20px !important;
    align-items: center !important;
    border-radius: 10px !important;
    background-color: #ffffff !important;
    border: 1px solid #d9d9d9 !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none;
    box-shadow: none;
  }

  .ant-select-selector {
    input {
      background: transparent !important;
    }
  }

}

.ant-select-item-option-content {
  text-transform: capitalize;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  opacity: .7;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-select-dropdown {
  border-radius: 10px !important;
  padding: 0;

  .ant-select-item {
    padding: 7px 12px 7px 20px;
    font-size: 0.9rem;
  }
}

.ant-select-multiple .ant-select-selection-item {
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  padding-inline-start: 10px !important;
  padding-inline-end: 10px !important;
  font-size: 0.9rem !important;
  margin-top: 0 !important;
  background: transparent !important;
  border: 2px solid;
  margin-inline-end: 6px !important;
  margin-bottom: 0 !important;
}


.ant-select-multiple .ant-select-selection-placeholder {
  left: 20px !important;
}

.select-wrapper---error{
  
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #e35061 !important;
  }

  .ant-select-multiple .ant-select-selector {
    border: 1px solid #e35061 !important;
  }
}


.dark-theme {
  .ant-select {
    background-color: #0a1f35 !important;
  }
  .ant-select-single .ant-select-selector {
    background-color: #0a1f35 !important;
    border: 1px solid transparent !important;
    color: #fff !important;
  }
  .ant-select-multiple .ant-select-selector {
    background-color: #0a1f35 !important;
    border: 1px solid transparent !important;
    color: #fff !important;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #fff!important;
  }
  .ant-select-selection-placeholder{
    color: #fff7 !important;
  }
}
.ant-select-single {
  border-radius: 20px;
}
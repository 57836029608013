.__collapse__items {

    .ant-collapse-header {
        font-weight: 600;
        font-family: 'Axiforma', sans-serif !important;
        font-size: 17px;
    }
    p{
        font-family: 'Axiforma', sans-serif !important;
    }
}
.__descr{
    line-height: 2;
    font-size: 15px;
    font-size: clamp(15px, 1vw, 20px);
    img{
        display: none !important;
    }
    h2{
        display: none;
    }
}
.__search_list {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
    grid-gap: 20px !important;
    padding-bottom: 20px !important;
    font-size: 13px !important;
}

.__pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        border-radius: 35px;
        font-size: 18px;
        line-height: 1;
        margin: 0;
        height: 60px;
        width: 150px;
        margin-top: 40px;
        background: #cacfa654;
        border: 1px dashed;
        color: #818d26;
        font-size: 19px;
    }
}


.__science{
    aspect-ratio: 12/9 !important;
    object-position: center !important;
}
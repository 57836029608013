.bt-ico-left {
    display: inline-flex;
    align-items: center;
    gap: 1em;
    cursor: pointer;
}

.head-toolbar__left {
    display: inline-flex;
    align-items: center;
    gap: 16px;
}

.head-toolbar__right {
    display: flex;
}

.toolbar-bts {
    --toolbar-gutter: 40px;
    display: inline-flex;
    gap: var(--toolbar-gutter);

    li {
        list-style: none;
    }
}


.__inside_detail{
    img{
        height: 60vh !important;
    }
    .thumb {img {
        height: 70px !important;
    }}
    .ant-skeleton{
        width: 100%;
    }
}

.m-asset__date{
    p{
        font-weight: bold;
    }
    button{
        height: 50px;
        margin-top: 20px;
        background: #c5363a;
    }
}

.__unsub{
    font-weight: bold;
    font-size: 16px;
    color: #818d26;
}
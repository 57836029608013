.profile {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 10vw;

    @media screen and (max-width: 555px) {
        flex-direction: column;
        gap: 0vw;
        justify-content: center;
    }

    .prfImg {
        position: relative;
        width: 200px;
        margin: 0 auto;
        margin-bottom: 3vw;

        img {
            width: 200px;
            height: 200px;
            object-fit: cover;
            border: 1px solid #0001;
            object-position: top;
        }

        .savePic {
            width: 40px;
            background: #ffffff;
            text-align: center;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            border-radius: 25px;
            position: absolute;
            bottom: -10px;
            right: -10px;
            z-index: 11;
            color: #97a51a;
            box-shadow: 0px 0px 10px #0003;
            font-size: 34px;
        }
    }

    .profCont {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;

        h3 {
            font-size: 20px;
            margin: 0;
        }

        a {
            font-size: 16px;
            padding: 11px 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #616161;
            text-decoration: none;
            min-width: 200px;

            &:hover {
                color: #97a51a;
                transition: 0.4s;
            }
        }
    }
}

.__collapse__items_settings {

    .ant-collapse-expand-icon {
        span {
            font-size: 17px !important;
            color: #6a6a6a !important;
            padding-left: 2px;
            padding-bottom: 3px;
            transition: 0.4s;
        }
    }

    .ant-collapse-header:hover {
        .ant-collapse-expand-icon {
            span {
                color: #97a51a !important;
            }
        }
    }

    .ant-collapse-header-text {
        a {
            padding: 0;
            font-family: 'Axiforma', sans-serif !important;
        }
    }

    .___subs_List {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 30px;

        .__sinlgle_sub_list_item {
            display: flex;
            align-items: center;
            gap: 25px;
            text-align: justify;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;
            position: relative;

            img {
                aspect-ratio: 4/4;
                width: 50px;
                height: 50px;
                object-fit: cover;
                object-position: center;
                border-radius: 7px;
            }

            h1 {
                font-size: 17px;
                font-weight: 500;
                margin: 0;
            }

            p {
                font-size: 14px;
                font-weight: 200;
                color: #5e670d;
            }

            span {
                b {
                    color: #b71111;
                }
            }

            button {
                position: absolute;
                right: 0;
                top: 10px;
                padding: 4px 9px;
                border: 1px solid #ddd;
                border-radius: 15px;
                background: #eee9;
            }
        }
    }

    .___subs_List>.__sinlgle_sub_list_item {
        padding-top: 10px;
        border-top: 1px solid #eee;
    }

    .___subs_List>.__sinlgle_sub_list_item~.__sinlgle_sub_list_item {
        padding-top: 0px;
        border-top: none
    }


}

.arabic {
    .___subs_List {
        .__sinlgle_sub_list_item {
            button {
                right: auto;
                left: 0;

            }
        }
    }
}


.ant-btn-primary {
    background-color: #97a51a !important;
}

.___health {

    svg {
        fill: #616161;

        &:hover {
            fill: #97a51a;
        }
    }
}
.bt-ico-left {
    display: inline-flex;
    align-items: center;
    gap: 1em;
    cursor: pointer;
}

.head-toolbar__left {
    display: inline-flex;
    align-items: center;
    gap: 16px;
}

.head-toolbar__right {
    display: flex;
}

.toolbar-bts {
    --toolbar-gutter: 40px;
    display: inline-flex;
    gap: var(--toolbar-gutter);

    li {
        list-style: none;
    }
}

.__webClam2 {
    -webkit-line-clamp: 2 !important;
}

.__webClam1 {
    -webkit-line-clamp: 1 !important;
}

.c-work_detail {
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px) {
        flex-direction: column;
    }

    .c-work_detail__left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .m-asset__title {
            margin: 10px 0;
            font-size: 25px;
            font-size: clamp(25px, 3vw, 56px);
            font-weight: 600;
        }

        .m-asset__footer {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 300;
        }

        @media (max-width: 800px) {
            width: 100%;
            margin-bottom: 30px;
            margin-right: 0;
            padding-right: 0;
        }
    }

    .c-work_detail__right {
        width: 14%;
        min-width: 230px;
        display: none;

        @media (max-width: 800px) {
            width: 100%;
        }
    }

}

.arabic{
    .c-work_detail .c-work_detail__left{
        margin-left: 3%;
    padding-left: 3%;
    margin-right: auto !important;
    padding-right: 0 !important;
    }
}


.__comment_sec {
    padding-top: 20px;
    border-top: 1px solid #ddd;
    margin-top: 20px;

    .__comnt_icon_set {
        display: flex;
        grid-gap: 30px;

        a {
            display: flex;
            align-items: center;
            grid-gap: 11px;
            font-size: 15px;
            cursor: pointer;


            p {
                font-size: 15px;
                color: #777;
                line-height: 1;
                font-weight: 500;
            }
        }

        svg {
            width: 18px;
            height: 18px;
            font-size: 18px;
        }
    }
}


.__common-head {
    font-size: 28px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 20px;
}


.__com-ed-wrap {
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;

    .__com_actions {
        display: flex;
        align-items: start;
        grid-gap: 5px;

        button {
            border: 0;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            background: #97a51a;
            color: #ffffff;
        }
    }
}




.__add_comment_wrap {
    margin: 10px 0;

    .__comment_adding {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        grid-gap: 15px;

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 60px;
            object-position: top;
        }

        div {
            width: 100%;
            text-align: center;
        }
    }
}


.__comment_wrap {
    display: flex;
    margin-bottom: 25px;

    img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 60px;
        background: #3c3c3c;
        object-position: top;
    }

    h1 {
        font-size: 18px !important;
        margin-bottom: 5px;

        span {
            font-size: 13px;
            margin: 0 10px;
            color: #aaa;
            font-weight: 400;
        }
    }

    p {
        font-size: 14px !important;
        color: #666 !important;
        margin-top: 5px !important;
        margin-bottom: 0 !important;
    }

}

.__newStyle_btn {button{
    margin: 0px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    padding: 0;
    min-width: 20px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 4px;
}
}

.__categ{
    cursor: pointer;
    color: #97a51a;
    transition: 0.4s;
    &:hover{
        color: #000;
    }
}


.__text_img_align {
    img{
        margin: 0 auto;
    }
}
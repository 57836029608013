.__address{
    ul{
        margin-bottom: 36px;
        li {
            font-size: 14px;
        }
        strong{
            font-size: 16px;
            margin-bottom: 2px;
            text-transform: uppercase;
        }
    }
    .__social__nav{
        display: flex;
        gap: 10px;
    }
}
.__subscribe_fn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    margin-top: 10vh;
    font-size: 22px;
    padding-bottom: 30px;
    text-align: center;
    border-top: 1px dashed #ededed;
    padding-top: 40px;
    border-radius: 210px;
    background: linear-gradient(180deg, #00000008, #fff);
    button{
        background: #cacfa654;
        color: #818d26;
        border: 1px dashed;
        font-size: 19px;
        transition: 0.4s;
        &:hover{
            box-shadow: 0px 0px 10px #0001;
        }
    }
}
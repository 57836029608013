._____descr {
    text-align: justify !important;
    line-height: 2;
    font-size: 18px;
    font-size: clamp(18px, 1.5vw, 28px);
    margin-top: 40px;

}

.__cover_im {
    margin-top: 30px;
    border-radius: 20px;
    width: clamp(100%, 80%, 90%);
}

.card-about {
    display: flex;
    gap: clamp(24px, 6vw, 6vw);

    @media (max-width: 555px) {
        flex-direction: column;
    }

    .card-about__left {
        display: flex;
        align-items: center;
        flex: 1;
    }

    .card-about__title {
        line-height: 120%;
    }

    .heading-5 {
        font-size: 26px;
        font-size: clamp(26px, 2.3vw, 56px);
        line-height: 120%;
        font-weight: 700;
        margin-top: 20px;
    }

    .card-about__excerpt {
        margin: clamp(24px, 3vw, 48px) 0;
        font-size: 18px;
        font-size: clamp(18px, 0.53vw, 24px);
        font-weight: 400;
        line-height: 150%;
    }

    .card-about__excerpt p:last-child {
        margin-bottom: 0;
    }

    .text-medium {
        font-size: 18px;
        font-size: clamp(18px, 0.2vw, 20px);
    }

    .bt-ico-left {
        display: inline-flex;
        align-items: center;
        gap: 0.5em;
        cursor: pointer;
    }

    .card-about__right {
        flex: 1;
    }

    .card-about__media {
        display: block;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 1rem;
        transition: all .3s;
    }
}

.__teams_list {
    grid-gap: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(clamp(200px, 15vw, 220px), 1fr));

    .__team_single {
        text-align: center;
        cursor: pointer;
        position: relative;

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            margin: 0 auto;
            filter: grayscale(1);
            transition: .6s;
            &:hover{
                filter: none;
            }
        }

        .__content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: 0.4s;
            padding: 16px 0;

            h3 {
                font-size: 18px;
                font-size: clamp(18px, 0.53vw, 24px);
                margin-bottom: 2px;
                font-weight: 600;
                text-transform: uppercase;
            }

            p {
                font-size: 14px;
                font-size: clamp(14px, 0.33vw, 18px);
                margin: 0;
            }
        }

    }
}
.card-about__excerpt{
    h2, img{
        display: none;
    }
    p{
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        flex: 1 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.__detail_modal {
    .ant-modal-content {
        background: #fcffe6;
    }
}

.head-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: clamp(20px, 3vw, 50px);
}

.c-work {
    display: flex;
    justify-content: space-between;
    // height: calc(100vh - 180px);
    height: calc(70vh - 180px);

    @media (max-width: 800px) {
        flex-direction: column;
        height: 100%;
    }

    .c-work__left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 46%;
        margin-right: 3%;
        padding-right: 3%;
        overflow-y: auto;

        @media (max-width: 800px) {
            width: 100%;
            margin-bottom: 30px;
            height: 40vh;
            margin-right: 0;
            padding-right: 0;
        }

        .m-asset {
            display: flex;
            flex-direction: column;
            padding-right: 8%;
            overflow-y: auto;

            .m-asset__header {
                margin: 16px 0;
            }

            .m-asset__title {
                width: 400px;
                max-width: 100%;
                font-size: 20px;
                font-weight: bold;
                line-height: 130%;
            }

            .m-asset__buy {
                display: flex;
                align-items: center;
                gap: 36px;
                margin-top: clamp(20px, 3vw, 40px);
                padding: clamp(14px, 2vw, 28px) 0;
                border-top: 1px solid #e9e9e9;
                border-bottom: 1px solid #e9e9e9;

                .button {
                    background: #ffa580;
                    color: #222;
                    padding: 20px;
                    border-radius: 4px;
                }

            }

            .m-asset__from {
                line-height: 100%;
            }

            .m-asset__price {
                font-size: 20px;
                font-weight: bold;
                line-height: 130%;
            }

            .m-asset__desc {
                margin: clamp(25px, 5vw, 50px) 0;
                font-size: 16px;
                line-height: 150%;
            }

            .m-asset__desc p {
                margin-bottom: 1.5em;
            }

            .m-asset__footer {
                display: flex;
                align-items: center;
                gap: clamp(40px, 5vw, 90px);
                padding-top: clamp(14px, 2vw, 28px);
                border-top: 1px solid #e9e9e9;

                div {
                    flex: 1;
                }
            }

            .m-asset__curated {
                display: flex;
                align-items: center;
                gap: 12px;
            }




        }
    }

    .m-asset::-webkit-scrollbar {
        width: 2px;
    }

    .m-asset::-webkit-scrollbar-thumb {
        background: #222;
    }

    .m-asset::-webkit-scrollbar-track {
        background: #22222217;
    }

    .c-work__right {
        width: 50%;
        min-width: 320px;

        img {
            height: 390px;
            width: 100%;
            object-fit: contain;
            border-radius: 10px;
            background-color: #fff;
        }

        @media (max-width: 800px) {
            width: 100%;
        }

        .carousel {
            .slide {
                cursor: pointer;
            }

            .thumb {
                transition: border .15s ease-in;
                display: inline-block;
                margin-right: 6px;
                white-space: nowrap;
                overflow: hidden;
                border: 1px solid transparent;
                padding: 4px;
                border-radius: 4px;

                &:hover {
                    border: 1px solid #222
                }

                img {
                    border-radius: 4px;
                    height: 70px;
                    background-color: #fff;
                }

                &.selected {
                    border: 1px solid #222
                }
            }

            .slider-wrapper {
                border-radius: 10px;
            }

            .thumbs {
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }

    }
}




.__photo {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    text-align: center;

    h1 {
        font-size: 24px;
        margin: 10px 0;
        font-weight: bold;
    }

    p {
        font-size: 17px;
        line-height: 2;
    }

    img {
        width: 100%;
        max-height: 70vh;
        object-fit: contain;
        min-height: 5vh;

    }

}
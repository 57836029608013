.map-container {
    width: 100%;
    height: 80vh;
    position: relative;
}

.fs-container {
    width: 100%;
    height: 100%;
}
.leaflet-container {
    outline: 0;
    cursor: -moz-grab;
    overflow: hidden;
    touch-action: none;
    font-family: inherit;
    font-size: 12px;
    line-height: 1.5;
    background: #ddd;
    outline-offset: 1px;
    -webkit-tap-highlight-color: transparent;
}
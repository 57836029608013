.__read_now {
    background: #818d26;
    padding: 10px 20px;
    border-radius: 21px;
    color: #fff;
    text-decoration: none;
    margin-top: 20px;

    &:hover {
        color: #fff;
        text-decoration: none;
    }
}


.__cover_im_book {
    margin-top: 30px;
    border-radius: 20px;
    width: clamp(100%, 80%, 90%);
    height: 340px;
    object-fit: cover;
}
._____descr_book{
    text-align: justify !important;
    line-height: 2;
    font-size: 22px;
    font-size: clamp(22px, 1.5vw, 30px);
    margin-top: 40px;
}
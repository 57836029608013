.arabic {
    .__arb{
        direction: rtl;
        input{
            font-size: 20px;
        }
    }
    .__icon{
        left: 16px !important;
        right: auto !important;
    }

    
}


@import url('https://fonts.googleapis.com/css2?family=Lateef:wght@200;300;400;500;600;700;800&display=swap');


@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import './fonts/spoof/Spoof-Regular.ttf';

@font-face {
    font-family: "Spoof Regular";
    src: local("Spoof Regular"),
        url("./fonts/spoof/Spoof-Regular.ttf") format("truetype");
    font-weight: 400;
}

@import './fonts/spoof/Spoof-Medium.ttf';

@font-face {
    font-family: "Spoof Medium";
    src: local("Spoof Medium"),
        url("./fonts/spoof/Spoof-Medium.ttf") format("truetype");
    font-weight: bold;
}

@import './fonts/spoof/Spoof-Black.ttf';

@font-face {
    font-family: "Spoof Black";
    src: local("Spoof Black"),
        url("./fonts/spoof/Spoof-Black.ttf") format("truetype");
    font-weight: 600;
}




@import './fonts/Axiforma/Axiforma-SemiBold.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma SemiBold'),
        url('./fonts/Axiforma/Axiforma-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@import './fonts/Axiforma/Axiforma-Thin.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Thin'),
        url('./fonts/Axiforma/Axiforma-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@import './fonts/Axiforma/Axiforma-Regular.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Regular'),
        url('./fonts/Axiforma/Axiforma-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@import './fonts/Axiforma/Axiforma-MediumItalic.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Medium Italic'),
        url('./fonts/Axiforma/Axiforma-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@import './fonts/Axiforma/Axiforma-Black.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Black'),
        url('./fonts/Axiforma/Axiforma-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@import './fonts/Axiforma/Axiforma-BlackItalic.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Black Italic'),
        url('./fonts/Axiforma/Axiforma-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@import './fonts/Axiforma/Axiforma-Heavy.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Heavy'),
        url('./fonts/Axiforma/Axiforma-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@import './fonts/Axiforma/Axiforma-Medium.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Medium'),
        url('./fonts/Axiforma/Axiforma-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@import './fonts/Axiforma/Axiforma-BoldItalic.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Bold Italic'),
        url('./fonts/Axiforma/Axiforma-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@import './fonts/Axiforma/Axiforma-ExtraBoldItalic.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma ExtraBold Italic'),
        url('./fonts/Axiforma/Axiforma-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@import './fonts/Axiforma/Axiforma-Book.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Book'),
        url('./fonts/Axiforma/Axiforma-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@import './fonts/Axiforma/Axiforma-Light.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Light'),
        url('./fonts/Axiforma/Axiforma-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@import './fonts/Axiforma/Axiforma-ThinItalic.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Thin Italic'),
        url('./fonts/Axiforma/Axiforma-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@import './fonts/Axiforma/Axiforma-Bold.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Bold'),
        url('./fonts/Axiforma/Axiforma-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@import './fonts/Axiforma/Axiforma-LightItalic.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Light Italic'),
        url('./fonts/Axiforma/Axiforma-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@import './fonts/Axiforma/Axiforma-SemiBoldItalic.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma SemiBold Italic'),
        url('./fonts/Axiforma/Axiforma-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@import './fonts/Axiforma/Axiforma-Italic.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Italic'),
        url('./fonts/Axiforma/Axiforma-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@import './fonts/Axiforma/Axiforma-BookItalic.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Book Italic'),
        url('./fonts/Axiforma/Axiforma-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@import './fonts/Axiforma/Axiforma-ExtraBold.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma ExtraBold'),
        url('./fonts/Axiforma/Axiforma-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@import './fonts/Axiforma/Axiforma-HeavyItalic.ttf';

@font-face {
    font-family: 'Axiforma';
    src: local('Axiforma Heavy Italic'),
        url('./fonts/Axiforma/Axiforma-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}






body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: 'Axiforma', sans-serif !important;
    position: relative;
}

.arabic {
    font-family: 'Lateef', serif !important;

    .ant-modal {
        font-family: 'Lateef', serif !important;
    }
}

.ant-modal {
    font-family: 'Axiforma', sans-serif !important;
}



.content-wrapper {
    min-height: 100vh;
    width: 100%;
    position: relative;
}

p {
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // font-family: 'Axiforma Bold', sans-serif !important;
    font-weight: 500;
}

a:hover {
    transition: 0.4s;
    color: #818d26;
}

.__inrTop {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 6.4em;
}


.inner {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 52px;

    @media (max-width: 556px) {
        padding: 0 0px;
    }

}

.box-featured {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: auto;
    padding: clamp(20px, 4vw, 32px);
    background: #f3f3f3;
    border-radius: 8px;
    line-height: 150%;
}


.c-heading {
    position: relative;
    margin-bottom: clamp(40px, 5vw, 96px);
    font-weight: 400;
}

.c-heading--small .c-heading__top {
    margin-bottom: 1rem;
}

.c-heading__top {
    margin-bottom: clamp(20px, 3vw, 32px);
    font-weight: 400;
}

.c-heading__top .text-default {
    font-weight: 400;
}

.text-default {
    font-size: 16px;
    font-size: clamp(16px, 2vw, 26px);
    font-weight: 300;
}

.arabic {
    .text-default {
        font-size: 22px;
        font-size: clamp(22px, 3vw, 36px);
        font-weight: 300;
    }
}

.c-heading__between {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 16px;
}

.heading-5 {
    font-size: 26px;
    font-size: clamp(26px, 3vw, 56px);
    line-height: 120%;
}

.l-list-sites {
    display: flex;
    flex-wrap: wrap;
    gap: clamp(30px, 3vw, 94px);
}

.l-list-sites__col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 340px;
}

.heading-hr {
    padding-bottom: 14px;
    font-size: 24px;
}

.l-list-sites .list-sites {
    flex: 1;
}

.list-sites__item:not(:last-child) {
    border-bottom: 1px solid #e9e9e9;
}

.list-sites__item {
    padding: 10px 0px;
    list-style: none;
}

.list-sites__item a {
    color: #000;
    text-decoration: none;
}

.list-sites__card {
    display: flex;
    align-items: center;
    gap: 30px;
}

.list-sites__figure {
    flex-shrink: 0;
}

.list-sites__media {
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    width: clamp(40px, 4vw, 72px);
    height: clamp(40px, 4vw, 72px);
    transition: all .3s;
}

.list-sites__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    min-width: 0;
}

.list-sites__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}


.budget-tag {
    display: inline-block;
    height: 22px;
    padding: 0 4px;
    line-height: 22px;
    font-size: 12px;
    font-weight: 500;

}

.list-sites__title--truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-sites__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 120%;
}

.has-sticker {
    position: relative;
}

.grid-courses {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    grid-auto-flow: row dense;
    grid-gap: 40px;
    padding-bottom: 30px;
    font-size: 15px;
    padding-left: 0;
}

.card-academy {
    --card-pad-x: clamp(20px, 3vw, 30px);
    --card-pad-y: clamp(10px, 3vw, 20px);
    --card-border: 1px solid #e9e9e9;
    --card-bg: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--card-bg);
    border-radius: 1rem;
    overflow: hidden;
}

.card-academy__figure {
    position: relative;
}

a {
    color: #000;
    text-decoration: none;
}

li {
    list-style: none;
}

.card-academy__media {
    display: block;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    aspect-ratio: 16/9;
    transition: all .3s;
    object-position: top;
}

figure img {
    display: block;
}

img {
    max-width: 100%;
}

img {
    border: none;
}

.card-academy__figure .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.card-academy__footer .card-academy__row {
    align-items: center;
    border-top: 1px solid #e9e9e9;
}

.card-academy__media:hover {
    filter: brightness(80%);
}

.card-academy__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.card-academy__row:first-child {
    padding-top: clamp(20px, 2vw, 20px);
    padding-bottom: clamp(20px, 2vw, 20px);
}

.card-academy__row {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: clamp(10px, 3vw, 20px) clamp(20px, 3vw, 30px);
}

.card-academy__row>*:first-child {
    flex: 1;
}

.card-academy__title {
    font-size: 18px;
    font-size: clamp(18px, 1vw, 24px);
    line-height: 120%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-academy__desc {
    line-height: 140%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
}


.card-academy__by {
    line-height: 120%;
    display: flex;
    align-items: center;
}

.box-price {
    display: flex;
    gap: 8px;
    line-height: normal;
}

.box-price__amount {
    display: flex;
    gap: 2px;
    height: 27px;
    font-size: 32px;
}

.box-price__previous {
    color: #fa5d29;
    text-decoration: line-through;
}

.box-price__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: normal;
    height: 100%;
}

.card-academy__stats {
    display: flex;
    line-height: 100%;
    font-size: 14px;
    gap: clamp(20px, 3vw, 30px);
}

.card-academy__stat {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.card-academy__more {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.c-view-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: clamp(30px, 5vw, 80px);
}

.c-view-all__row {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: 555px) {
        flex-direction: column;
    }
}

.text-medium {
    font-size: 18px;
    font-size: clamp(18px, 1vw, 20px);
}

.bt-ico-left {
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
}

.link-underlined {
    position: relative;
    display: inline-block;
    line-height: 100%;
    color: rgb(34, 34, 34);
    border: none;
    cursor: pointer;
}

.link-underlined:before {
    content: "";
    position: absolute;
    bottom: -0.2em;
    left: 0;
    width: 100%;
    height: 2px;
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, rgb(34, 34, 34) 45%, rgba(34, 34, 34, 0.3) 55%);
    background-size: 220% 100%;
    background-position: 100% 50%;
    transition: .3s ease-out;
}

.__articles {
    .card-academy__media {
        padding: 10px;
        border-radius: 20px;
    }

    figure {
        margin-bottom: 4px;
    }

    .card-academy__row:first-child {
        padding-top: clamp(20px, 2vw, 20px);
        padding-bottom: clamp(20px, 2vw, 20px);
    }

    .card-academy__row {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding: clamp(10px, 2vw, 20px) clamp(20px, 2vw, 20px);
        flex-direction: column;
        white-space: pre-wrap;

        p {
            background: #8bc34a;
            position: absolute;
            padding: 6px 10px;
            font-size: 14px;
            color: #fff;
            top: 17px;
            z-index: 1;
            filter: drop-shadow(2px 4px 6px #0002);
            border-radius: 3px;
            left: 0px;
            right: auto;
            margin: 0;
            line-height: 1;
            padding-top: 8px;
        }
    }

    .__anchor {
        margin-top: 10px;
        font-weight: bold;
        cursor: pointer;
    }

}

.imageLoader {
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
    animation: 1.5s shine linear infinite;
    background-size: 200% 100%;
}

.__common_model_class {

    .ant-modal-content {
        color: #333;
        overflow-y: auto;
        max-height: 80vh;

    }


    .__common_model_content {
        display: flex;
        flex-direction: column;


        .____common_model_class_head {
            font-size: 19px;
            font-weight: 500;
            margin-bottom: 30px;
            text-align: left;
            width: 100%;
        }

        .____common_model_class_desc {
            font-size: 15px;
            font-weight: 300;
        }

    }
}

.__innerTopPad {
    padding-top: 100px;
}

.arabic {
    direction: rtl;
    text-align: justify;
    animation: fadein .4s ease-in;

    .c-work .c-work__left {
        margin-right: auto !important;
        padding-right: 0 !important;
        margin-left: 3%;
        padding-left: 3%;
    }

    .c-work .c-work__left .m-asset {
        padding-right: 0 !important;
        padding-left: 8%;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}



.__articles_list {
    .c-heading {
        position: relative;
        margin-bottom: clamp(40px, 5vw, 96px);
        font-weight: 400;
    }

    .c-heading__top {
        margin-bottom: clamp(20px, 3vw, 32px);
        font-weight: 400;
    }

    .c-heading__middle {
        h3 {
            font-size: 36px;
            font-weight: 600;
            font-size: clamp(36px, 5vw, 96px);
        }
    }

    .c-heading__middle+.c-heading__bottom {
        margin-top: 1em;
    }

    .c-heading__short-desc {
        display: inline-block;
        width: 360px;
        max-width: 100%;
        font-size: 18px;
    }
}


.__no_result {
    margin-top: 4em;
    display: flex;
    font-size: clamp(19px, 1.3vw, 33px);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin-bottom: 20px;
    }
}


.arabic {
    .c-heading__between {
        font-size: 23px;
    }

    .card-academy__title {
        font-size: 23px;
        font-size: clamp(23px, 1.8vw, 30px);
    }

    .card-academy__desc {
        font-size: 20px;
    }

    .card-academy__title {
        font-size: 29px !important;
    }

    .card-academy__by {
        font-size: 20px;
    }

    .card-academy__stat {
        a {
            font-size: 20px;
        }
    }

    .__articles .card-academy__row p {
        font-size: 19px;
    }

    .__articles_list .c-heading__short-desc {
        font-size: 23px;
    }

    .m-asset {
        font-size: 23px;
    }

    .m-asset__desc p {
        font-size: 24px !important;
    }

    .c-work .c-work__left .m-asset .m-asset__title {
        font-size: 30px;
    }

    .list-sites__title {
        font-size: 24px;
    }

    .list-sites__label {
        font-size: 18px;
    }
}


body.arabic {
    p {
        font-size: 22px;
    }
}

.__otp_content {
    input {
        margin: 0 10px;
        border: 1px solid rgba(147, 159, 50, 0.45);
        outline: none;
        height: 48px;
        font-size: 1.5rem;
        padding: 0px 10px;
        border-radius: 25px;
        color: rgb(110, 110, 110);
        transition: all 0.1s ease 0s;
        width: 48px !important;
    }
}

.__filterEnabled {
    display: flex !important;
    gap: 10px;

    @media (max-width: 555px) {
        flex-direction: column-reverse;
    }


    .inner {
        flex: 7;
    }

    .__filter_set {
        flex: 3;

        .__filter_title {
            font-size: 26px;
            font-weight: 600;
            font-size: clamp(26px, 3vw, 56px);
            margin-bottom: 20px;
        }

        .__filt__item {
            display: flex;
            gap: 15px;
            align-items: center;
            margin-bottom: 20px;
            cursor: pointer;
            transition: 0.4s;
            border-radius: 40px;
            padding: 0 3px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.034);

            }

            .__filt__media {
                border-radius: 50%;
                height: clamp(22px, 2vw, 42px);
                object-fit: cover;
                transition: all .3s;
                width: clamp(22px, 2vw, 42px);
            }

            h3 {
                font-size: 14px;
                font-size: clamp(14px, 1vw, 23px);
                margin: 0;
                line-height: 1;
                font-weight: 500;
                transition: 0.4s;
            }

            .active {
                font-weight: 700;
                color: #818d26;
            }
        }
    }
}


.arabic {
    .__filterEnabled .__filter_set .__filt__item h3 {
        font-size: 19px !important;
    }
}


.__awards_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 30px;
    width: 100%;

    .__dummy {
        border-radius: 20px;
        box-shadow: 0px 0px 10px #0002;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 235;
        width: 100%;
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
        color: #818d26;
    }
}

.__sec_hd {
    margin-bottom: 30px;
    font-weight: 500;
    line-height: 1.2;
    position: relative;
    font-size: 2.5rem;

    &:after {
        height: 7px;
        width: 60px;
        background: #818d267c;
        content: " ";
        position: absolute;
        left: 0px;
        bottom: -10px;
        border-radius: 30px;
        border-left: 14px solid #818d26;
    }
}


.singleBook {
    box-shadow: 0 0 7px #00000033;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    transition: .5s;
    text-decoration: none;
    color: #000;

    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        filter: drop-shadow(0 2px 6px #0007);
    }

    .bookBind {
        position: absolute;
        width: 7px;
        height: 100%;
        border-right: 2px solid #00000088;
        box-shadow: 0 0 4px #ffffff73;
        left: 0;
        top: 0;
    }

    .subJect {
        top: 10px;
        position: absolute;
        right: 0;
        padding: 2px 10px;
        background: #c5393a;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        color: #fff;
        max-width: 140px;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
        word-break: break-all;
    }

    h1 {
        margin: 0;
        margin-top: 10px;
        font-size: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
    }

    h5 {
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 5px;
        color: #767676 !important;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
    }

    h6 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
    }
}

.singleBookInner {
    padding: 15px;
}

.__filt_btn {
    cursor: pointer;
    width: 40px !important;
    height: 40px !important;
    border: 1px solid #eee;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;

    &:hover {
        background-color: #818d26 !important;

        svg {
            path {
                transition: 0.4s;
                fill: #fff;
            }
        }
    }
}

.ant-radio-button-wrapper-checked {
    background: #818d26 !important;
    border-color: #818d26 !important;
}




._cht_full {
    margin-top: 15px;
    margin-bottom: 35px;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;

    .__share {
        cursor: pointer;

        svg {
            font-size: 18px;
            height: 18px;
            width: 18px;
        }

    }

    .__chat_heading {
        margin-top: 0px;
        margin-bottom: 15px;
        font-size: 20px;
    }

    .__input_gpt {
        position: relative;

        button {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 120px;
            width: 80px;
            height: 48px;
        }
    }

    .__resp_gpt {
        font-size: 15px;
        margin-top: 15px;
        font-weight: 300;
        margin-bottom: 0px;
        line-height: 1.5;
        padding: 10px 30px;

        h3 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
            text-transform: capitalize;
        }

        p {
            font-size: 15px;
            font-weight: 300;
            line-height: 1.8;
            text-align: justify;
        }
    }

}

.arabic {
    ._cht_full {

        .__input_gpt {

            button {
                position: absolute;
                top: 0;
                right: auto;
                left: 0;
                min-width: 120px;
                width: 80px;
            }
        }

    }
}

.__faq {
    cursor: pointer;
    align-items: center;
    color: #444;
    display: flex;
    font-size: 15px;
    gap: 10px;
    justify-content: end;
    margin-top: 7px;
    width: -webkit-max-content;
    width: max-content;
}
#footer {
    padding-top: 40px;
    border-top: 1px solid #eee4;
    padding-bottom: 30px;
    background-color: #363435;
    color: #fff;

    img {
        height: 70px;
    }

    // .footer__top {
    //     margin-bottom: clamp(20px, 2vw, 70px);
    // }

    .footer__logo {
        display: inline-flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: clamp(20px, 2vw, 40px);
        color: #ffffffce;
    }

    .footer__wrapper {
        display: flex;

        @media screen and (max-width: 555px) {
            flex-direction: column;
            gap: 30px;
        }

    }

    .footer__grid {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 24px;
    }

    .footer__menu {
        font-weight: bold;
        line-height: 100%;
    }

    .footer__menu li:not(:last-child) {
        margin-bottom: clamp(10px, 4vw, 20px);
    }

    a {
        color: inherit;
        font-size: 15px;
        font-weight: 500;
        transition: 0.4s;

        &:hover {
            color: #818d26;
        }
    }

    .footer__top .box-featured {
        background: #E9E9E9;
    }

    .link-underlined {
        position: relative;
        display: inline-block;
        line-height: 100%;
        color: rgb(34, 34, 34);
        border: none;
        cursor: pointer;
    }

    .footer__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: clamp(14px, 2vw, 28px);
        font-size: 14px;
        font-weight: 400;
        background-image: linear-gradient(to right, rgb(255, 255, 255) 0 10%, rgba(255, 255, 255, 0) 10%);
        background-position: top;
        background-size: 8px 1px;
        background-repeat: repeat-x;

        @media screen and (max-width: 555px) {
            flex-direction: column;
        }


        a {
            font-size: 13px;
            font-weight: 300;

            &:hover {
                color: #818d26;
                transition: ease-in-out .4s;
            }
        }

        .footer__nav {
            display: flex;
            flex-wrap: wrap;
            gap: 0 24px;
            margin-top: 10px;
            text-align: center;

        }

        .footer__left,
        .footer__right {
            flex: 1;
        }

        .footer__right {
            display: flex;
            justify-content: end;
        }

        img {
            height: 26px;
        }
    }

    .___official {
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        margin: 20px 0;
        border-top: 1px solid #434343;
        padding-top: 20px;
    }
}

.arabic {
    .___official {
        font-size: 20px !important;
    }
    
    .footer__bottom {
        font-size: 20px;
        a{
            font-size: 20px;
        }
    }

    #footer a {
        font-size: 20px;
    }
}
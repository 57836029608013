.__gallery_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 30px;

    .__gallery_item {
        cursor: pointer;
        opacity: 1;
        transition: 0.4s;
        &:hover{
            opacity: 0.8;
        }
        img {
            height: 220px;
            object-fit: cover;
            width: 100%;
            border-radius: 10px;
        }
        h4 {
            text-align: center;
            font-size: 21px;
            margin: 10px 0;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            flex: 1 1;
            overflow: hidden;
        }
    }
}
.__back_texture {
    background: url('./../../../assets/images/pattern_web.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 900px;
    height: 100%;
    width: 100%;
}


.__img1 {
    width: 320px !important;

    @media screen and (max-width: 1200px) {
        width: 220px !important;
    }
}

.__img2 {
    width: 290px !important;

    @media screen and (max-width: 1200px) {
        width: 160px !important;
    }
}

.__img3 {
    width: 380px !important;
}

.__img4 {
    width: 320px !important;
}

.__home_banner {
    position: relative;
    min-height: 100vh;
    padding-top: 30px;

    @media screen and (max-width: 767px) {
        min-height: 80vh;
        padding-top: 110px;
    }
    @media screen and (max-height: 767px) {
        padding-top: 90px;
    }


    .__bnr_content {
        position: relative;

        .__hme_animator span{
            font-size: 3.5rem;
            @media screen and (max-width: 555px) {
                font-size: 2.65rem !important;
           }   
        }

        .__toph {
            padding: 5px 15px;
            border: 1px solid #ddd;
            border-radius: 20px;
            color: #818d26;
            background-color: #fff;
            font-weight: 500;
            text-decoration: none;
            line-height: 0;
            padding-top: 9px;
        }


        h2 {
            font-size: 2.1rem;

            @media screen and (max-width: 555px) {
                font-size: 1.5rem;
            }
        }

        .__bnr-actions {
            display: flex;
            grid-gap: 0px;
            margin-top: 15px;


            .button.primary {
                border: 1px solid transparent !important;
                transition: border 0.5s ease;
            }

            .button.large {
                border-style: solid;
                border-width: 1px;
                padding: 15px 48px;
                transition: color .2s, background-color .2s;
            }

            .button {
                position: relative;
                overflow: hidden;
            }

            .button {
                background-color: #818d26;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 8px;
                justify-content: center;
                align-items: center;
                padding: 8px 20px;
                font-size: 18px;
                font-weight: 400;
                line-height: 1;
                display: flex;
                position: relative;
                overflow: hidden;
            }

            .w-inline-block {
                max-width: 100%;
                display: inline-block;
            }

            .button.primary .btn-text {
                color: #fff !important;
                transition: color 0.2s ease;
            }

            .button .btn-text,
            .button .button__circle {
                pointer-events: none;
            }

            .btn-text {
                z-index: 1;
                display: inline-block;
                position: relative;
            }

            .button .button__circle {
                --x-circle: 234px;
                --y-circle: 52px;
                --transition-expo-out: cubic-bezier(0.16, 1, 0.3, 1);
                --transition-expo-in: cubic-bezier(0.7, 0, 0.84, 0);
                --transition-cubic-out: cubic-bezier(.09, .56, .61, .99);
                position: absolute;
                top: var(--y-circle);
                left: var(--x-circle);
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.5);
                transform: scale(0);
                transition: transform 1s var(--transition-expo-out);
                z-index: 0;
            }

            .button.primary:hover {
                border: 1px solid #818d26 !important;
            }

            .button.primary:hover .btn-text {
                color: #818d26 !important;
            }

            .button:hover .button__circle {
                transform: scale(50);
                transition: transform 1.2s cubic-bezier(.09, .56, .61, .99);

            }
        }
    }

}

.__business_strip {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    font-size: 18px;
    margin-top: 30px;
    font-weight: bold;
    margin-bottom: 20px;

    img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 0px 0px 1px #001;
    }

    h3 {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 11px;
        letter-spacing: 1px;
    }

}

.ant-dropdown {
    z-index: 11111;
}

.__side_img {
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        object-fit: contain;
    }
}

.__logos_roll {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 80px;
    height: 100px;

}

._home_title {
    font-size: 55px;
    font-weight: 500;
    margin-top: 10px;
}

._home_title2 {
    font-size: 60px;
    font-weight: 300;
    margin-bottom: 30px;
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
}

.__block {
    padding: 0;
    margin-top: clamp(20px, 3vw, 50px);
    margin-bottom: 50px;
}




.__award_lists {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;

}

.__line {
    width: 100%;
    border-bottom: 1px solid #818d2661;
    position: absolute;
}

.__single_award {
    text-align: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 1;
    position: relative;
    margin-right: 30px;
    width: 150px;

    .ant-avatar {
        width: 40px;
        height: 40px;
    }

    &:after {
        width: 30px;
        height: 1px;
        background: #fff;
        content: '';
        position: absolute;
        right: -30px;
    }

    &:last-child {
        margin-right: 0px;

        &:after {
            display: none;
        }
    }

    h1 {
        font-size: 17px;
        margin: 10px 0;
        min-height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
    }

    .__round {
        width: 16px;
        height: 16px;
        background-color: #818d26;
        border-radius: 10px;
    }

    .__dashed {
        height: 110px;
        border-left: 2px dashed #818d26;
    }

    .__year {
        width: 140px;
        background: #818d26;
        box-shadow: 0px 0px 10px #0004;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        font-size: 19px;
        font-weight: 600;
        margin: 30px 0;
        color: #fff;
        line-height: 1;
        padding-top: 3px;
    }

    .imgss {
        width: 140px;
        height: 240px;
        object-fit: cover;
        border-radius: 30px;
    }

    .__category {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1;
        font-size: 13px;
        position: absolute;
        top: 23px;
        right: -13px;
        background: #d30505;
        color: #fff;
        padding: 4px 10px;
        border-radius: 10px 0;
        box-shadow: 0px 0px 10px #0004;
        text-align: center;
        width: 100%;
        word-break: break-all;
    }

    .__category_bot {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1;
        font-size: 13px;
        position: absolute;
        bottom: 23px;
        left: -13px;
        background: #d30505;
        color: #fff;
        padding: 4px 10px;
        border-radius: 10px 0;
        box-shadow: 0px 0px 10px #0004;
        text-align: center;
        width: 100%;
        word-break: break-all;
    }
}

.__award_button {
    border-radius: 50px;
    height: 43px;
    width: 43px;
    border: none;
    color: #555;
    background: #fff;
    box-shadow: 0px 0px 10px #0003;
    transition: 0.4s;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        box-shadow: 0px 0px 10px #0006;
    }
}

.arabic{
    .__home_banner .__bnr_content, .__business_strip h3{
        font-size: 22px;
    }
    .__home_banner .__bnr_content .__bnr-actions .button{
        font-size: 24px;
    }
}